.sidebar {
  transition: width 200ms linear;
  margin: 24px;
  padding: 32px 24px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: auto;
  padding-top: 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08);
}

.show {
  width: 100%;
  opacity: 1;
  transition: width opacity display 200ms linear;
}

.hide {
  display: none;
  width: 0%;
  opacity: 0;
}

.sidebarIconBtn {
  cursor: pointer;
}

.sidebarItemContainer:hover {
  background-color: #8f52eb1a;
}

.sidebar2-item-margin {
  margin-left: 24px;
}
